import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    ExtrasTypesStatus: 'idle',
    rentableExtraTypes: [],
    rentableType: null,
};
// ----------------------------------------------------------------------
export const getRentableExtraTypes = createAsyncThunk('vehicle/getRentableExtraTypes', async ({ rentableType = '', isPaging = true }) => {
    let data;
    try {
        const response = await axios.get(`/rentableExtraTypes?search=rentableTypeId:${rentableType}&isPaging=${isPaging}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getRentableType = createAsyncThunk('vehicle/getRentableType', async ({ rentableType = '' }) => {
    let data;
    try {
        const response = await axios.get(`/rentableExtraTypes?search=rentableType.id:${rentableType}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data[0];
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'rentableExtraType',
    initialState,
    reducers: {
        handleResetRentalType: (state, action) => {
            state.rentableType = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRentableExtraTypes.pending, (state) => {
            state.ExtrasTypesStatus = 'loading';
        })
            .addCase(getRentableExtraTypes.fulfilled, (state, action) => {
            state.ExtrasTypesStatus = 'succeeded';
            state.rentableExtraTypes = action.payload;
        })
            .addCase(getRentableExtraTypes.rejected, (state, action) => {
            state.ExtrasTypesStatus = 'failed';
        })
            .addCase(getRentableType.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getRentableType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.rentableType = action.payload;
        })
            .addCase(getRentableType.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const { handleResetRentalType } = slice.actions;
export const reducer = slice.reducer;
export default slice;
